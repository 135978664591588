import React, { useEffect, useState } from 'react'

import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import MainImages from '../components/mainimages/MainImages'
import StarParalax from '../components/starparalax/StarParalax'
import TimeLine from '../components/timeline/TimeLine'
import ContactUs from '../components/contactus/ContactUs'
import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'
import MailChimp from '../components/mailchimp/MailChimp'
import ButtonSet from '../components/buttonset/ButtonSet'
import SocialBar from '../components/socialbar/SocialBar'

import Devider from '../assets/path-1.svg'
import DeviderSecond from '../assets/path-2.svg'


const Home = () => {
    return (
        <>

            <SocialLinks />
            {/* <NavBar /> */}
            <SocialBar />
            <StarParalax />
            {/* <MainImages />
            <ButtonSet />
            <TimeLine /> */}
            {/* <ContactUs /> */}
            <Footer />
            <ScrollTop />

        </>
    )
}

export default Home
