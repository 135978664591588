import React, { useEffect, useState } from 'react'
import Gabbung from '../../assets/hero-animation/gabung.png'
import Logo from '../../assets/logo.png'
import Charity from '../../assets/charity-fund.png'
import Marketing from '../../assets/marketing.png'
import LottieImage from '../lottieimage/LottieImage'
import Logo01 from '../../assets/newlogo/logo01.png'
import Logo02 from '../../assets/newlogo/logo02.png'
import logotext from '../../assets/logo-text.png'
import TamilData from '../tamilcharity/TamilData'
import MainPlay from '../playlist/MainPlay'
import CharityRd from '../charityrd/CharityRd'


import { getServerSideProps } from '../../components/playlist/PlayList'


const StarParalax = () => {

    const [playList, setPlayList] = useState([])

    useEffect(async () => {
        let data = await getServerSideProps();
        console.log(data)
        setPlayList(data.props.data.items)
    }, [])

    return (
        <div className="starparalax-v2">
            {/* <div class="background">
                <img src={backgroundimg} alt="" />
            </div> */}
            <div className="hero-main-content">
                <div className="hero-sub">
                    <div className="container">
                        <div className="hero-header">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="hero-header-text">
                                        {/* <h1>SAFEPIZZA is now</h1> */}
                                        <img src={logotext} alt="" className="img-responsive" />
                                        {/* <h1>STORE COMING SOON JAN 2022</h1>
                                        <h2>CREATE, BUY, BID, SELL OR TRADE NFTS IN BINANCE SMART CHAIN</h2> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container">
                <CharityRd />
            </div>
            <div className="container charity-h3">
                <h3>Tamil Charity Program</h3>
                <TamilData />
                <h3>Mumbai Charity Program</h3>
                <MainPlay />
            </div>
        </div>
    )
}

export default StarParalax
