import React, { useEffect, useState } from 'react'
import { getServerSideProps } from './PlayList'


const MainPlay = () => {

    const [playList, setPlayList] = useState([])

    useEffect(async () => {
        let data = await getServerSideProps();
        console.log(data)
        setPlayList(data.props.data.items)
    }, [])

    return (
        <div className="charity-playlist">
            <div className="container">
                {playList.map(({ id, snippet = {} }) => {
                    const { title, thumbnails = {}, resourceId = {} } = snippet;
                    const { medium } = thumbnails;

                    if (medium?.url) {
                        return (
                            <>
                                <div className="video-card">
                                    <span key={id}>
                                        <a target="_blank" href={`https://www.youtube.com/watch?v=${resourceId.videoId}`}>
                                            <p>
                                                <img width={medium?.width} height={medium?.height} src={medium.url} alt="" />
                                            </p>
                                            <h3>{title}</h3>
                                        </a>
                                    </span>
                                </div>
                            </>
                        )
                    }

                })}
            </div>
        </div>
    )
}

export default MainPlay
