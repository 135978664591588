import React, { useEffect } from 'react'
import Coin from '../../assets/icons/coin.svg'
import Dex from '../../assets/icons/dex.svg'
import Geko from '../../assets/icons/geko.svg'
import Logo from '../../assets/home-logo.png'
import HomeIcon from '../../assets/home-page.png'


const SocialLinks = () => {
    useEffect(() => {
        document.getElementById("google_translate_element").hidden = false;
    }, [])
    return (
        <div className="social-main-content">
            <div className="container">
                <div className="social-container">
                    <div className="logo-container">
                        <a href="/"><img src={Logo} alt="" /></a>
                        <div className="home-icon-logo">
                            <a href="https://www.pizza-nft.com"><img src={HomeIcon} alt="Home" className="home-logo" />Back to Pizza NFT</a>
                        </div>
                    </div>
                    <div class="google-translate-div ">
                        <div id="google_translate_element"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialLinks
