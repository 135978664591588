import DefaultLayout from '../layout/DefaultLayout';
import Home from '../screens/home'
import HowtoBuy from '../screens/HowtoBuy'
import Media from '../screens/media'
import Team from '../screens/team'
import Document from '../screens/documents';
import LoadingScreen from '../screens/loading';
import Marketing from '../screens/marketing';
import FaqScreen from '../screens/faqscreen';
import Charity from '../screens/charity';
import LightPaper from '../screens/lightpaper';
import PizzaNft from '../screens/pizzanft';
import Market from '../screens/market'


const routes = [
    {
        path: `/`,
        exact: true,
        layout: DefaultLayout,
        component: Home,
    }
]


export default routes;