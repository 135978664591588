import React, { useEffect, useState } from 'react'
import Gabbung from '../../assets/hero-animation/gabung.png'
import Logo from '../../assets/logo.png'
import Charity from '../../assets/charity-fund.png'
import Marketing from '../../assets/marketing.png'
import LottieImage from '../lottieimage/LottieImage'
import Logo01 from '../../assets/newlogo/logo01.png'
import Logo02 from '../../assets/newlogo/logo02.png'
import logotext from '../../assets/logo-text.png'

import { getServerSideProps } from './TamilCharity'


const TamilData = () => {

    const [playList, setPlayList] = useState([])

    useEffect(async () => {
        let data = await getServerSideProps();
        console.log(data)
        setPlayList(data.props.data.items)
    }, [])

    return (
        <div className="charity-playlist">
            <div className="container">
                {playList.map(({ id, snippet = {} }) => {
                    const { title, thumbnails = {}, resourceId = {} } = snippet;
                    const { medium } = thumbnails;

                    if (medium?.url) {
                        return (
                            <div className="video-card">
                                <span key={id}>
                                    <a target="_blank" href={`https://www.youtube.com/watch?v=${resourceId.videoId}`}>
                                        <p>
                                            <img width={medium?.width} height={medium?.height} src={medium.url} alt="" />
                                        </p>
                                        <h3>{title}</h3>
                                    </a>
                                </span>
                            </div>
                        )
                    }

                })}
            </div>
        </div>
    )
}

export default TamilData
