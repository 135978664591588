
const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PLJc7cD9v8QGAwyvoLl5IY8LtavslBty2r&key=AIzaSyB90cIkChMSvgk4amZ41PRR8ZpbGAaT1dU';

export async function getServerSideProps() {
    const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}`)
    const data = await res.json();
    return {
        props: {
            data
        }
    }
}
